import { useEffect, useState} from 'react';
import { Outlet } from "react-router-dom";

import VerticalNav from "../../components/core/VerticalNav";
import Nav from "../../components/core/Nav";

import './styles.css';

const ServiceErrorBanner = ({ isVisible }) => {
  if (!isVisible) return null;
  
  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      backgroundColor: '#f44336',
      color: 'white',
      padding: '10px',
      textAlign: 'center',
      zIndex: 9999
    }}>
      Backend services are currently unavailable. Some features may be limited.
    </div>
  );
};

const Layout = () => {
  const [isAuthenticated] = useState(localStorage.getItem('isAuthenticated') === 'true');
  const [hasBackendError, setHasBackendError] = useState(false);
  
  useEffect(() => {
    localStorage.setItem('isAuthenticated', isAuthenticated);
  }, [isAuthenticated]);

  useEffect(() => {
    const checkBackendHealth = async () => {
      try {
        const response = await fetch('/api/health/');
        setHasBackendError(!response.ok);
      } catch (error) {
        setHasBackendError(true);
      }
    };
    
    checkBackendHealth();
    const interval = setInterval(checkBackendHealth, 30000); // Check every 30 seconds
    
    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <ServiceErrorBanner isVisible={hasBackendError} />
      <div style={{ 
        backgroundImage: `url(https://www.allured.com/wp-content/uploads/2014/11/home-1.jpg)`,
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
        backgroundPosition: 'center',
        minHeight: '100vh'
        }}>
          <Nav />
          <div className="flex main-content">
              <VerticalNav />
              <div style={{ 
                marginLeft: '250px',
                padding: '1.25rem',
                width: 'calc(100% - 250px)',
                minHeight: 'calc(100vh - 64px)'
              }}>
                <Outlet />
              </div>
          </div>
      </div>
    </div>
  )
};

export default Layout;