import { fetchFromApi } from "../../services";

export const fetchRepData = async (sold_by_email, timeframe) => {
    console.log('Fetching rep data for', sold_by_email, 'on timeframe', timeframe);
    try {
        const response = await fetchFromApi("/sales-analytics", { sold_by: sold_by_email, timeframe: timeframe });
        console.log('Successfully fetched rep data:', response);
        return response; // Return data to the caller
    } catch (error) {
        console.log("Something went wrong:", error);
        return null; // Return null or appropriate error handling
    }
}