import React, { useContext, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { Panel } from 'primereact/panel';
import { Card } from 'primereact/card';
import { ProgressSpinner } from 'primereact/progressspinner';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import useRepData from './useRepData';
import BarChart from '../../components/charts/BarChart';
import { CampaignsByProduct } from '../Explore/AdvertiserCampaigns/modules/campaignsByProduct';
import ReportsDetail from '../ReportsDetail';

// Add styles for gradient headers
const gradientHeaderStyle = {
    '.p-panel-header': {
        background: 'linear-gradient(135deg, var(--gradient-start) 0%, var(--gradient-end) 100%)',
        color: 'white',
        border: 'none'
    },
    '.p-panel-content': {
        borderTopWidth: '0'
    }
};

const Dashboard = () => {
    const navigate = useNavigate();
    const { user, spoofedUser } = useContext(AuthContext);
    const [selectedTimeframe, setSelectedTimeframe] = useState('allTime');
    const [selectedCampaign, setSelectedCampaign] = useState(null);
    const [reportVisible, setReportVisible] = useState(false);
    const { repData, loading, error } = useRepData(spoofedUser || user, selectedTimeframe);

    const timeframeOptions = [
        { label: 'All Time', value: 'allTime' },
        { label: 'This Month', value: 'thisMonth' },
        { label: 'Last Month', value: 'lastMonth' },
        { label: 'Last 3 Months', value: 'last3Months' },
        { label: 'This Year', value: 'thisYear' }
    ];

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'
        }).format(value || 0);
    };

    const formatNumber = (value) => {
        return new Intl.NumberFormat('en-US').format(value || 0);
    };

    const formatPercentage = (value) => {
        return new Intl.NumberFormat('en-US', {
            style: 'percent',
            minimumFractionDigits: 1,
            maximumFractionDigits: 1
        }).format((value || 0) / 100);
    };

    const viewCampaignReport = (campaign) => {
        navigate(`/reports/${campaign.id}`);
    };

    console.log('RepData:', repData);

    return (
        <div className="dashboard-container">
            {error && (
                <div className="p-message p-message-error mb-3">
                    <div className="p-message-text">{error}</div>
                </div>
            )}
            
            <div className="flex align-items-center justify-content-between mb-3">
                <div>
                    <h2 className="mb-1">Sales Dashboard</h2>
                    <p className="text-500 m-0">Welcome back, {(spoofedUser || user)?.name || 'User'}</p>
                </div>
                <div className="flex gap-2">
                    <Dropdown
                        value={selectedTimeframe}
                        options={timeframeOptions}
                        onChange={(e) => setSelectedTimeframe(e.value)}
                        placeholder="Select Timeframe"
                    />
                </div>
            </div>

            {loading ? (
                <div className="flex justify-content-center align-items-center" style={{ minHeight: '200px' }}>
                    <i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }} />
                </div>
            ) : !repData ? (
                <div className="flex justify-content-center align-items-center" style={{ minHeight: '200px' }}>
                    <div className="text-500">No data available</div>
                </div>
            ) : (
                <div className="grid">
                    {/* Quick Stats Row */}
                    <div className="col-12">
                        <div className="grid">
                            {/* Total Campaigns Card */}
                            <div className="col-12 md:col-6 lg:col-3">
                                <div className="p-4 border-round bg-primary" style={{ background: 'linear-gradient(135deg, var(--primary-600) 0%, var(--primary-800) 100%)' }}>
                                    <div className="text-0 mb-2">Total Campaigns</div>
                                    <div className="text-0 text-4xl font-bold mb-2">{formatNumber(repData.totalCampaigns)}</div>
                                    <div className="flex align-items-center">
                                        <i className={`pi ${repData.campaignGrowth >= 0 ? 'pi-arrow-up text-green-300' : 'pi-arrow-down text-red-300'} mr-2`} />
                                        <span className="text-0">{formatPercentage(repData.campaignGrowth)} growth</span>
                                    </div>
                                </div>
                            </div>

                            {/* New vs Repeat Business Card */}
                            <div className="col-12 md:col-6 lg:col-3">
                                <div className="p-4 border-round" style={{ background: 'linear-gradient(135deg, var(--green-600) 0%, var(--green-800) 100%)' }}>
                                    <div className="text-0 mb-2">New vs Repeat</div>
                                    <div className="text-0 text-4xl font-bold mb-2">
                                        {formatNumber(repData.newAdvertisers)} / {formatNumber(repData.repeatAdvertisers)}
                                    </div>
                                    <div className="flex align-items-center">
                                        <i className="pi pi-users text-0 mr-2" />
                                        <span className="text-0">{formatPercentage(repData.repeatAdvertisers / (repData.totalAdvertisers || 1))} repeat</span>
                                    </div>
                                </div>
                            </div>

                            {/* Campaign Mix Card */}
                            <div className="col-12 md:col-6 lg:col-3">
                                <div className="p-4 border-round" style={{ background: 'linear-gradient(135deg, var(--orange-600) 0%, var(--orange-800) 100%)' }}>
                                    <div className="text-0 mb-2">Top Campaign Type</div>
                                    <div className="text-0 text-4xl font-bold mb-2">
                                        {repData.topProduct || 'N/A'}
                                    </div>
                                    <div className="flex align-items-center">
                                        <i className="pi pi-tag text-0 mr-2" />
                                        <span className="text-0">{formatPercentage(repData.topProductPercentage)} of campaigns</span>
                                    </div>
                                </div>
                            </div>

                            {/* Active Campaigns Card */}
                            <div className="col-12 md:col-6 lg:col-3">
                                <div className="p-4 border-round" style={{ background: 'linear-gradient(135deg, var(--purple-600) 0%, var(--purple-800) 100%)' }}>
                                    <div className="text-0 mb-2">Active Campaigns</div>
                                    <div className="text-0 text-4xl font-bold mb-2">
                                        {formatNumber(repData.activeCampaigns)}
                                    </div>
                                    <div className="flex align-items-center">
                                        <i className="pi pi-clock text-0 mr-2" />
                                        <span className="text-0">currently running</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Distribution Panels */}
                    <div className="col-12 md:col-6">
                        <Panel 
                            header="Campaign Distribution by Product" 
                            className="shadow-2"
                            headerStyle={{
                                background: 'linear-gradient(135deg, var(--gradient-start) 0%, var(--gradient-end) 100%)',
                                color: 'white',
                                border: 'none'
                            }}
                            contentStyle={{
                                borderTopWidth: 0,
                                padding: '0.75rem'
                            }}
                            style={{
                                '--gradient-start': 'var(--primary-600)',
                                '--gradient-end': 'var(--primary-800)'
                            }}
                        >
                            <div className="flex flex-column gap-2">
                                {repData.campaignsByProduct?.map((item, index) => {
                                    const percentage = (item.count / repData.totalCampaigns) * 100;
                                    const colors = ['var(--primary-500)', 'var(--primary-400)', 'var(--primary-300)', 'var(--primary-200)'];
                                    return (
                                        <div key={index} className="flex align-items-center gap-2 mb-2">
                                            <div className="w-4 font-medium text-sm">{item.name}</div>
                                            <div className="flex-1 relative h-2rem bg-surface-200 border-round overflow-hidden">
                                                <div 
                                                    className="absolute h-full border-round transition-width transition-duration-500 flex align-items-center" 
                                                    style={{ 
                                                        width: `${percentage}%`,
                                                        background: colors[index % colors.length]
                                                    }}
                                                />
                                                <div className="absolute right-0 h-full px-2 flex align-items-center">
                                                    <span className="text-primary-700 font-bold text-sm white-space-nowrap">
                                                        {item.count} ({percentage.toFixed(1)}%)
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }) || <p className="text-500">No campaign data available</p>}
                            </div>
                        </Panel>
                    </div>
                    <div className="col-12 md:col-6">
                        <Panel 
                            header="Top 10 Advertisers" 
                            className="shadow-2"
                            headerStyle={{
                                background: 'linear-gradient(135deg, var(--gradient-start) 0%, var(--gradient-end) 100%)',
                                color: 'white',
                                border: 'none'
                            }}
                            contentStyle={{
                                borderTopWidth: 0,
                                padding: '0.75rem'
                            }}
                            style={{
                                '--gradient-start': 'var(--cyan-600)',
                                '--gradient-end': 'var(--cyan-800)'
                            }}
                        >
                            <div className="flex flex-column gap-2">
                                {repData.advertiserDistribution?.map((item, index) => {
                                    const maxCount = Math.max(...repData.advertiserDistribution.map(i => i.count));
                                    const percentage = (item.count / maxCount) * 100;
                                    const colors = ['var(--cyan-500)', 'var(--cyan-400)', 'var(--cyan-300)', 'var(--cyan-200)'];
                                    return (
                                        <div key={index} className="flex align-items-center gap-2 mb-2">
                                            <div className="w-4 font-medium text-sm">{item.name}</div>
                                            <div className="flex-1 relative h-2rem bg-surface-200 border-round overflow-hidden">
                                                <div 
                                                    className="absolute h-full border-round transition-width transition-duration-500 flex align-items-center" 
                                                    style={{ 
                                                        width: `${percentage}%`,
                                                        background: colors[index % colors.length]
                                                    }}
                                                />
                                                <div className="absolute right-0 h-full px-2 flex align-items-center">
                                                    <span className="text-cyan-700 font-bold text-sm white-space-nowrap">
                                                        {item.count} campaigns
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }) || <p className="text-500">No advertiser data available</p>}
                            </div>
                        </Panel>
                    </div>

                    {/* Active Campaigns Table */}
                    <div className="col-12">
                        <Panel 
                            header="Active Campaigns" 
                            className="shadow-2"
                            headerStyle={{
                                background: 'linear-gradient(135deg, var(--gradient-start) 0%, var(--gradient-end) 100%)',
                                color: 'white',
                                border: 'none'
                            }}
                            contentStyle={{
                                borderTopWidth: 0,
                                padding: '0.75rem'
                            }}
                            style={{
                                '--gradient-start': 'var(--blue-600)',
                                '--gradient-end': 'var(--blue-800)'
                            }}
                        >
                            <DataTable 
                                value={repData.activeCampaignsList || []} 
                                paginator 
                                rows={5}
                                emptyMessage="No active campaigns found"
                                className="p-datatable-sm cursor-pointer"
                                onRowClick={(e) => navigate(`/reports/${e.data.reference_id}`)}
                                rowClassName={() => 'bg-primary-50'}
                            >
                                <Column field="advertiser__name" header="Advertiser" />
                                <Column field="product__name" header="Product" />
                                <Column field="brand__name" header="Brand" />
                                <Column 
                                    field="start_date" 
                                    header="Start Date"
                                    body={(rowData) => new Date(rowData.start_date).toLocaleDateString()}
                                />
                                <Column 
                                    field="status" 
                                    header="Status"
                                    body={(rowData) => (
                                        <span className={`status-badge status-${rowData?.status?.toLowerCase()}`}>
                                            {rowData?.status}
                                        </span>
                                    )}
                                />
                            </DataTable>
                        </Panel>
                    </div>
                    <div className="col-12">
                        <Panel 
                            header={`Completed Campaigns (${repData.previousCampaigns || 0})`}
                            className="shadow-2"
                            headerStyle={{
                                background: 'linear-gradient(135deg, var(--gradient-start) 0%, var(--gradient-end) 100%)',
                                color: 'white',
                                border: 'none'
                            }}
                            contentStyle={{
                                borderTopWidth: 0,
                                padding: '0.75rem'
                            }}
                            style={{
                                '--gradient-start': 'var(--orange-600)',
                                '--gradient-end': 'var(--orange-800)'
                            }}
                        >
                            <DataTable 
                                value={repData.previousCampaignsList} 
                                paginator 
                                rows={5}
                                emptyMessage="No completed campaigns found"
                                className="p-datatable-sm cursor-pointer"
                                selectionMode="single"
                                onRowClick={(e) => {
                                    setSelectedCampaign(e.data);
                                    setReportVisible(true);
                                }}
                            >
                                <Column field="advertiser__name" header="Advertiser" />
                                <Column field="product__name" header="Product" />
                                <Column field="brand__name" header="Brand" />
                                <Column 
                                    field="start_date" 
                                    header="Campaign Start"
                                    body={(rowData) => new Date(rowData.start_date).toLocaleDateString()}
                                />
                                <Column 
                                    field="status" 
                                    header="Status"
                                    body={(rowData) => (
                                        <span className="text-orange-600 font-medium">{rowData.status}</span>
                                    )}
                                />
                            </DataTable>
                        </Panel>
                    </div>
                </div>
            )}
            <Dialog
                visible={reportVisible}
                onHide={() => setReportVisible(false)}
                style={{ width: '90vw' }}
                modal
                className="p-fluid"
                maximizable
            >
                {selectedCampaign && (
                    <ReportsDetail campaignId={selectedCampaign.id} />
                )}
            </Dialog>
        </div>
    );
};

export default Dashboard;