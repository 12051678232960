import {fetchFromApi}  from '../services'

// Add error handling wrapper
const withErrorHandling = async (apiCall) => {
  try {
    const response = await apiCall();
    return { data: response, error: null };
  } catch (error) {
    console.error('API Error [withErrorHandling]:', error);
    
    // Check if it's a backend connectivity issue
    if (!error.response || error.code === 'ECONNABORTED') {
      return {
        data: null,
        error: {
          message: 'Unable to connect to the server. Please try again later.',
          isConnectionError: true
        }
      };
    }
    
    return {
      data: null,
      error: {
        message: error.response?.data?.message || 'An error occurred',
        status: error.response?.status
      }
    };
  }
};

export const getAdvertisers = async () => {
  return withErrorHandling(() => fetchFromApi("/advertisers/"));
}

export const getAdvertiserCampaigns = async (advertiser_id) => {
  return withErrorHandling(() => fetchFromApi(`/advertisers/${advertiser_id}/campaigns/`));
}

export const getCampaigns = async () => {
  return withErrorHandling(() => fetchFromApi("/getcampaigns/"));
}

export const getAdvertiserLeads = async (advertiserId) => {
  return withErrorHandling(() => fetchFromApi('/advertisers/' + advertiserId + '/leads'));
}

export const getCampaign = async (campaignId) => {
  return withErrorHandling(() => fetchFromApi(`/reports/`, { campaign_id: campaignId }));
}

export const getCampaignOverviewReport = async ({advertiser_id, year, brand, userEmail}) => {
  return withErrorHandling(() => fetchFromApi(`/overview/generateOverviewReport`, { advertiser_id: advertiser_id, year: year, brand: brand, user: userEmail }));
}