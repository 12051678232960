import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const VerticalNav = () => {
    const [hoveredParent, setHoveredParent] = useState(null);
    const [hoveredChild, setHoveredChild] = useState(null);
    const [expandedMenus, setExpandedMenus] = useState({});

    const gradients = [
        'linear-gradient(to right, #ff4b4b, #ff8c42)', // Red to Orange
        'linear-gradient(to right, #ffa600, #ffce47)', // Orange to Yellow
        'linear-gradient(to right, #4caf50, #2ecc71)', // Green to Emerald
        'linear-gradient(to right, #2196f3, #00bcd4)', // Blue to Cyan
        'linear-gradient(to right, #9c27b0, #673ab7)', // Purple to Violet
        'linear-gradient(to right, #e91e63, #ff4081)', // Pink to Rose
        'linear-gradient(to right, #d81b60, #e91e63)', // Deep Pink to Pink
    ];

    const toggleMenu = (index) => {
        setExpandedMenus(prev => ({
            ...prev,
            [index]: !prev[index]
        }));
    };

    const getLinkStyle = (parentIndex, childIndex = null) => ({
        display: 'flex',
        alignItems: 'center',
        padding: childIndex !== null ? '8px 24px 8px 48px' : '12px 24px',
        margin: '0 -8px',
        borderRadius: '8px',
        transition: 'all 0.2s ease',
        background: (childIndex !== null ? 
            hoveredChild === `${parentIndex}-${childIndex}` : 
            hoveredParent === parentIndex) ? 
            gradients[parentIndex] : 'transparent',
        transform: (childIndex !== null ? 
            hoveredChild === `${parentIndex}-${childIndex}` : 
            hoveredParent === parentIndex) ? 
            'translateX(-10px)' : 'none',
        boxShadow: (childIndex !== null ? 
            hoveredChild === `${parentIndex}-${childIndex}` : 
            hoveredParent === parentIndex) ? 
            '0 4px 6px rgba(0, 0, 0, 0.1)' : 'none',
        cursor: 'pointer',
    });
    const menuItems = [
        {
            title: 'Dashboard',
            icon: 'dashboard',
            link: '/',
        },
        {
            title: 'Reports',
            icon: 'description',
            items: [
                {
                    title: 'Overview',
                    icon: 'assessment',
                    link: '/reports',
                },
                {
                    title: 'Campaign Reports',
                    icon: 'campaign',
                    link: '/reports/campaigns',
                },
                {
                    title: 'Lead Reports',
                    icon: 'people',
                    link: '/reports/leads',
                },
                {
                    title: 'Revenue Reports',
                    icon: 'monetization_on',
                    link: '/reports/revenue',
                },
            ],
        },
        {
            title: 'Advertisers',
            icon: 'explore',
            link: '/explore',
        },
        {
            title: 'Tools',
            icon: 'build',
            link: '/tools',
        },
        {
            title: 'Settings',
            icon: 'settings',
            link: '/settings',
        },
        {
            title: 'FAQ',
            icon: 'info',
            link: '/faq',
        },
        {
            title: 'Help',
            icon: 'bug_report',
            link: '/help',
        },
    ]
    return (
        <div className="sidenav-fixed" style={{
            background: "linear-gradient(135deg, #06385a 0%, #7fcff6 100%)",
            boxShadow: "2px 2px 3px #888, -2px 2px 3px #888",
            position: 'fixed',
            top: '64px', // Height of top nav
            left: 0,
            bottom: 0,
            width: '250px',
            paddingTop: '1rem',
        }}>
            <ul className="list-none text-white" id="my-sidenav" style={{ padding: 0 }}>
                {menuItems.map((item, index) => (
                    <li key={index} className="mb-2 px-0">
                        {item.items ? (
                            <div>
                                <div 
                                    style={getLinkStyle(index)}
                                    className="text-white no-underline w-full flex justify-between items-center"
                                    onMouseEnter={() => setHoveredParent(index)}
                                    onMouseLeave={() => setHoveredParent(null)}
                                    onClick={() => toggleMenu(index)}
                                >
                                    <div className="flex items-center">
                                        <i className="material-icons" style={{ marginRight: '12px' }}>{item.icon}</i>
                                        <span>{item.title}</span>
                                    </div>
                                    <i className="material-icons">
                                        {expandedMenus[index] ? 'expand_less' : 'expand_more'}
                                    </i>
                                </div>
                                {expandedMenus[index] && (
                                    <ul className="list-none pl-0 mt-1" style={{ 
                                        transition: 'all 0.3s ease',
                                        maxHeight: expandedMenus[index] ? '500px' : '0',
                                        overflow: 'hidden'
                                    }}>
                                        {item.items.map((subItem, subIndex) => (
                                            <li key={`${index}-${subIndex}`} className="mb-1">
                                                <Link
                                                    to={subItem.link}
                                                    style={getLinkStyle(index, subIndex)}
                                                    className="text-white no-underline w-full"
                                                    onMouseEnter={() => setHoveredChild(`${index}-${subIndex}`)}
                                                    onMouseLeave={() => setHoveredChild(null)}
                                                >
                                                    <i className="material-icons" style={{ marginRight: '12px', fontSize: '20px' }}>{subItem.icon}</i>
                                                    <span style={{ fontSize: '0.95em' }}>{subItem.title}</span>
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        ) : (
                            <Link 
                                to={item.link} 
                                style={getLinkStyle(index)}
                                className="text-white no-underline w-full flex"
                                onMouseEnter={() => setHoveredParent(index)}
                                onMouseLeave={() => setHoveredParent(null)}
                            >
                                <i className="material-icons" style={{ marginRight: '12px' }}>{item.icon}</i>
                                <span>{item.title}</span>
                            </Link>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default VerticalNav;
