import { useEffect } from 'react';
import styled from 'styled-components';
import SocialLoginGoogle from './SocialLoginGoogle';

const StyledBackgroundBlur = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(5px);
    z-index: 1;
`;

const StyledLoginCard = styled.div`
    // background-color: #f0f0f0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 2rem;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    text-align: center;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    min-height: 300px;
    max-height: 500px;
    border: 4px solid #3c8eec;
`;

const LoginCard = () => {
    // When this component is displayed the background should be masked
    // so that only the card is scene. This can be done by adding a
    // backdrop-filter to the body element. This will blur the background
    // and make the card stand out.
    // Get the body element and add a class to it that will apply the
    // backdrop-filter.
    
    useEffect(() => {
        console.log('Adding backdrop-filter');
        document.body.classList.add('backdrop-filter');
        return () => {
            document.body.classList.remove('backdrop-filter');
        };
    }, []);

    return (
        <StyledBackgroundBlur>
            <StyledLoginCard>
                <h1>You're not logged in!</h1>
                <h3>let's fix that...</h3>
                <SocialLoginGoogle />
            </StyledLoginCard>
        </StyledBackgroundBlur>
    )
}    // When this component is displayed the background should be masked
    // so that only the card is scene. This can be done by adding a
    // backdrop-filter to the body element. This will blur the background
    // and make the card stand out.
    // Get the body element and add a class to it that will apply the
    // backdrop-filter.
    

export default LoginCard;