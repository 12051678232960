import { Chart } from "primereact/chart";
import ChartDataLabels from 'chartjs-plugin-datalabels';

export const CampaignsByProduct = ({ campaigns }) => {
	const campaigns_by_product = campaigns?.reduce((acc, campaign) => {
		if (!acc[campaign.product]) {
			acc[campaign.product] = [];
		}
		acc[campaign.product].push(campaign);
		return acc;
	}, {});
	const chartData = !campaigns
		? {}
		: {
				labels: Object.keys(campaigns_by_product),
				datasets: [
					{
						data: Object.values(campaigns_by_product).map(
							(campaigns) => campaigns.length
						),
						backgroundColor: [
							"#FF6384", // Pink
							"#36A2EB", // Blue
							"#FFCE56", // Yellow
							"#4BC0C0", // Teal
							"#9966FF", // Purple
							"#FF9F40", // Orange
							"#7CBA3B", // Green
							"#EC774B", // Coral
							"#5F9EA0", // Cadet Blue
							"#BC8F8F"  // Rosy Brown
						],
						hoverBackgroundColor: [
							"#D14D6A", // Darker Pink
							"#2B82BD", // Darker Blue
							"#D1A844", // Darker Yellow
							"#3D9999", // Darker Teal
							"#7A51CC", // Darker Purple
							"#CC7F33", // Darker Orange
							"#639430", // Darker Green
							"#BD5F3C", // Darker Coral
							"#4C7E80", // Darker Cadet Blue
							"#967272"  // Darker Rosy Brown
						],
					},
				],
		  };

	const options = {
		plugins: {
			legend: {
				display: false
			},
			tooltip: {
				callbacks: {
					label: (context) => {
						const value = context.raw;
						const total = context.dataset.data.reduce((acc, data) => acc + data, 0);
						const percentage = ((value * 100) / total).toFixed(1);
						return `${context.label}: ${value} (${percentage}%)`;
					}
				}
			},
			datalabels: {
				color: '#333',
				font: {
					weight: 'bold',
					size: 12
				},
				formatter: (value, context) => {
					const total = context.dataset.data.reduce((acc, data) => acc + data, 0);
					const percentage = ((value * 100) / total).toFixed(1);
					const label = context.chart.data.labels[context.dataIndex];
					return [
						label,
						`${value} (${percentage}%)`
					];
				},
				textAlign: 'left',
				padding: 6,
				anchor: 'end',
				align: 'end',
				offset: 8,
				borderWidth: 2,
				borderColor: context => context.dataset.backgroundColor[context.dataIndex],
				borderRadius: 4,
				backgroundColor: 'white',
				display: context => context.dataset.data[context.dataIndex] > 0
			}
		},
		layout: {
			padding: {
				top: 75,
				bottom: 75,
				left: 75,
				right: 75
			}
		},
		maintainAspectRatio: false
	};

	return (
		<div className="flex justify-content-center align-items-center w-full" style={{ height: '22rem' }}>
			<Chart
				type="doughnut"
				data={chartData}
				options={options}
				plugins={[ChartDataLabels]}
				className="w-11 h-25rem"
			/>
		</div>
	);
};