import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { TabMenu } from "primereact/tabmenu";
import { useState } from "react";
import { Link } from "react-router-dom";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";

export const StyledReportLink = ({ href, icon, children }) => (
	<a
		href={href}
		target="_blank"
		rel="noreferrer"
		style={{
			display: 'inline-flex',
			alignItems: 'center',
			padding: '8px 16px',
			margin: '0px',
			background: '#f8f9fa',
			border: '1px solid #dee2e6',
			borderRadius: '4px',
			color: '#1565c0',
			textDecoration: 'none',
			transition: 'all 0.2s ease',
			fontSize: '14px',
			fontWeight: '500'
		}}
		onMouseOver={(e) => {
			e.target.style.background = '#e9ecef';
			e.target.style.borderColor = '#adb5bd';
		}}
		onMouseOut={(e) => {
			e.target.style.background = '#f8f9fa';
			e.target.style.borderColor = '#dee2e6';
		}}
	>
		{icon && <span style={{ marginRight: '8px' }}>{icon}</span>}
		{children}
	</a>
);

export const ReportHeader = ({ campaign, reportName }) => {
	const formatDate = (dateString) => {
		const date = new Date(dateString);
		return date.toLocaleDateString('en-US', {
			month: 'long',
			day: 'numeric',
			year: 'numeric'
		});
	};
	const logo = (brand) => {
		return (
			<img
				src={require(`../../../assets/images/logos/${campaign?.brand.toLowerCase()}_logo.png`)}
				alt={brand}
				style={{ maxWidth: "100%", height: "auto" }}
			/>
		);
	};
	return (
		<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', width: '100%' }}>
			<div id="report_info" style={{ flex: '1' }}>
				<h1
					className={"blue-text text-darken-4"}
					style={{ fontSize: 36, marginTop: 0, marginBottom: '10px' }}
				>
					{/* link to advertisers campaigns in /explore route...advertiser id to state */}
					<Link
						to={`/explore/`}
						state={{
							selectedAdvertiser: {
								id: campaign.advertiser_id,
								name: campaign.advertiser,
							},
						}}
						style={{
							textDecoration: "none",
							color: "#1565c0",
							transition: "color 0.3s ease"
						}}
						onMouseOver={(e) => e.target.style.color = "#2196f3"}
						onMouseOut={(e) => e.target.style.color = "#1565c0"}
					>
						{campaign.advertiser}
					</Link>
				</h1>
				<h2 style={{ fontSize: 22, lineHeight: 1, marginTop: "-1%" }}>
					{reportName}
				</h2>
				<h2 style={{ fontSize: 18, marginTop: -5 }}>{formatDate(campaign.start_date)}</h2>
			</div>
			<div style={{ flexShrink: 0 }}>
				{logo(campaign.brand)}
			</div>
		</div>
	);
};

export const BreakingNewsReport = ({ data }) => {
	return NewsletterAdTemplate({ data, reportName: "Breaking News Report" });
};

export const WebsiteAdReport = ({ data }) => {
	const campaign = data.campaign;
	const analytics = data.analytics;
	return (
		<>
			<ReportHeader campaign={campaign} reportName="Website Ad Report" />
			<DataTable value={analytics} className="w-full">
				<Column
					body={(rowData) => {
						const date = new Date(rowData.start_date);
						const options = { month: "long", year: "numeric" };
						return date.toLocaleDateString(undefined, options);
					}}
					field="start_date"
					header="Date"
				></Column>
				<Column body={() => campaign.advertiser} header="Advertiser"></Column>
				<Column field="total_impressions" header="Total Impressions"></Column>
				<Column field="total_clicks" header="Total Clicks"></Column>
				<Column
					field="total_ctr"
					header="Total Click Rate"
					body={(rowData) => `${(rowData.total_ctr * 100).toFixed(2)}%`}
				></Column>
			</DataTable>
		</>
	);
};

export const ProductRoundUpReport = ({ data }) => {
	const campaign = data.campaign;
	const analytics = data.analytics;
	const marketingInquiries = data?.marketingInquiries;

	const columns = [
		{ field: "deployment_total_delivered", header: "Delivered" },
		{ field: "deployment_total_opens", header: "Total Opens" },
		{ field: "deployment_unique_opens", header: "Unique Opens" },
		{
			field: "deployment_total_open_rate",
			header: "Total Open Rate",
			body: (rowData) =>
				`${(rowData.deployment_total_open_rate * 100).toFixed(2)}%`,
		},
		{
			field: "deployment_unique_open_rate",
			header: "Unique Open Rate",
			body: (rowData) =>
				`${(rowData.deployment_unique_open_rate * 100).toFixed(2)}%`,
		},
		{ field: "total_clicks", header: "Total Clicks" },
		{
			field: "total_click_rate",
			header: "Total Click Rate",
			body: (rowData) => `${(rowData.total_click_rate * 100).toFixed(2)}%`,
		},
		{
			field: "marketing_inquries_count",
			header: "Marketing Inquiries",
			body: () => marketingInquiries?.length || 0,
		},
	];
	return (
		<>
			<ReportHeader campaign={campaign} reportName="Product Roundup Report" />
			<DataTable value={analytics} className="w-full">
				{columns.map((col) => (
					<Column
						field={col.field}
						header={col.header}
						key={col.field}
						body={col?.body || null}
					/>
				))}
			</DataTable>
		</>
	);
};

export const EblastReport = ({ data }) => {
	const campaign = data.campaign;
	const stats = data.emailDeployments;

	const formatPercentage = (rowData, column) => {
		return `${(rowData[column.field] * 100).toFixed(2)}%`;
	};

	return (
		<div>
			<ReportHeader campaign={campaign} reportName="Dedicated Email Report" />
			<h3 style={{ fontSize: 24 }}>{stats.subject_line}</h3>
			<DataTable showGridlines value={[stats]}>
				<Column field="deployment_date" header="Deployment Date"></Column>
				<Column field="total_opens" header="Total Opens"></Column>
				<Column field="unique_opens" header="Unique Opens"></Column>
				<Column
					field="total_opens_delivered_rate"
					header="Opens/Delivered Rate"
					body={(rowData) =>
						formatPercentage(rowData, { field: "total_opens_delivered_rate" })
					}
				></Column>
				<Column
					field="unique_opens_delivered_rate"
					header="Unique Opens/Delivered Rate"
					body={(rowData) =>
						formatPercentage(rowData, { field: "unique_opens_delivered_rate" })
					}
				></Column>
				<Column field="total_clicks" header="Total Clicks"></Column>
				<Column field="unique_clicks" header="Unique Clicks"></Column>
				<Column
					field="total_clicks_delivered_rate"
					header="Clicks/Delivered Rate"
					body={(rowData) =>
						formatPercentage(rowData, { field: "total_clicks_delivered_rate" })
					}
				></Column>
				<Column
					field="total_clicks_opens_rate"
					header="Clicks/Opens Rate"
					body={(rowData) =>
						formatPercentage(rowData, { field: "total_clicks_opens_rate" })
					}
				></Column>
				<Column
					field="unique_clicks_opens_rate"
					header="Unique Clicks/Opens Rate"
					body={(rowData) =>
						formatPercentage(rowData, { field: "unique_clicks_opens_rate" })
					}
				></Column>
			</DataTable>
			<div style={{ display: 'flex', gap: '10px', margin: '16px 0' }}>
				<StyledReportLink href={stats.deployment_preview} icon="📧">View Deployment Preview</StyledReportLink>
			</div>
		</div>
	);
};

export const WebcastReport = ({ data }) => {
	const PerformanceTab = ({ data }) => {
		const transformedData = [
			{
				start_date: data.campaign.start_date,
				total_registered: data.analytics.total_registered,
				total_attendees: data.analytics.total_attended,
				on_demand: data.analytics.on_demand,
				attendee_conversion: data.analytics.attendee_conversion,
				questions: data.questions.length,
			},
		];
		return (
			<div>
				<h1
					style={{
						border: "2px solid #4ca4ff",
						padding: "10px",
						fontSize: "15px",
						fontWeight: "bold",
						background: "#eaf2ff",
						borderRadius: "4px",
					}}
				>
					Webcast Performance
				</h1>
				<DataTable value={transformedData} className="w-full">
					<Column field="start_date" header="Date"></Column>
					<Column field="total_registered" header="Total Registered"></Column>
					<Column field="total_attendees" header="Total Attendees"></Column>
					<Column field="on_demand" header="On Demand"></Column>
					<Column
						field="attendee_conversion"
						header="Total Reg to Attendees"
					></Column>
					<Column header="Total Questions" field="questions"></Column>
				</DataTable>
			</div>
		);
	};

	const RegistrantsTab = ({ registrants }) => {
		return (
			<div>
				<h1
					style={{
						border: "2px solid #4ca4ff",
						padding: "10px",
						fontSize: "15px",
						fontWeight: "bold",
						background: "#eaf2ff",
						borderRadius: "4px",
					}}
				>
					Total Registered Users: {registrants.length}
				</h1>
				<DataTable
					paginator
					rows={10}
					rowsPerPageOptions={[5, 10, 25, 50]}
					value={registrants}
					className="w-full"
				>
					<Column field="first_name" header="First Name"></Column>
					<Column field="last_name" header="Last Name"></Column>
					<Column field="email" header="Email"></Column>
					<Column field="company" header="Company"></Column>
					<Column field="job_function" header="Job Function"></Column>
				</DataTable>
			</div>
		);
	};

	const QuestionsTab = ({ questions }) => {
		return (
			<div>
				<h1
					style={{
						border: "2px solid #4ca4ff",
						padding: "10px",
						fontSize: "15px",
						fontWeight: "bold",
						background: "#eaf2ff",
						borderRadius: "4px",
					}}
				>
					Total Questions: {questions.length}
				</h1>
				<DataTable
					paginator
					rows={10}
					rowsPerPageOptions={[5, 10, 25, 50]}
					value={questions}
					className="w-full"
				>
					<Column field="question" header="Question"></Column>
					<Column field="answer" header="Answer"></Column>
					<Column field="asked_by" header="Asked By"></Column>
					<Column field="answered_by" header="Answered By"></Column>
				</DataTable>
			</div>
		);
	};

	const tabs = [
		{
			label: "Performance",
			key: "Performance",
			content: <PerformanceTab data={data} />,
		},
		{
			label: "Registrants",
			key: "Registrants",
			content: <RegistrantsTab registrants={data.registrants} />,
		},
		{
			label: "Questions",
			key: "Questions",
			content: <QuestionsTab questions={data.questions} />,
		},
	];

	const [activeIndex, setActiveIndex] = useState(3);
	const [activeTab, setActiveTab] = useState(tabs[0]);

	return (
		<div>
			<ReportHeader campaign={data?.campaign} reportName="Webcast Report" />
			<TabMenu
				model={tabs}
				activeIndex={activeIndex}
				onTabChange={(e) => {
					setActiveTab(e.value);
					setActiveIndex(e.index);
				}}
			/>
			<div>{activeTab.content}</div>
		</div>
	);
};

export const SponsoredContentReport = ({ data }) => {
	const campaign = data.campaign;
	const directLeads = data?.directLeads;
	const miLeads = data?.miLeads;

	const PerformanceTab = ({ data }) => {
		if (!data.analytics) {
			return (
				<p style={{ fontSize: "20px", paddingTop: "10px" }}>
					No analytics data available.
				</p>
			);
		}
		const transformedData = [
			{
				metric: "Pageviews",
				month_1: data.analytics.month_1?.pageviews,
				month_2: data.analytics.month_2?.pageviews,
				month_3: data.analytics.month_3?.pageviews,
			},
			{
				metric: "Leads",
				month_1: data.analytics.month_1?.leads,
				month_2: data.analytics.month_2?.leads,
				month_3: data.analytics.month_3?.leads,
			},
		];
		return (
			<div>
				<h1
					style={{
						border: "2px solid #4ca4ff",
						padding: "10px",
						fontSize: "15px",
						fontWeight: "bold",
						background: "#eaf2ff",
						borderRadius: "4px",
					}}
				>
					Webpage Performance
				</h1>
				<DataTable value={transformedData} tableStyle={{ minWidth: "50rem" }}>
					<column field="metric"></column>
					<column field="month_1" header="Month 1"></column>
					<column field="month_2" header="Month 2"></column>
					<column field="month_3" header="Month 3"></column>
					<column
						header="Total"
						body={(rowData) => rowData.month_1 + rowData.month_2 + rowData.month_3}
					></column>
				</DataTable>

				<h1
					style={{
						border: "2px solid #4ca4ff",
						padding: "10px",
						fontSize: "15px",
						fontWeight: "bold",
						background: "#eaf2ff",
						borderRadius: "4px",
					}}
				>
					Newsletter Performance
				</h1>
				<DataTable value={data?.newsletterAds} tableStyle={{ minWidth: "50rem" }}>
					<column field="deployment_date" header="Deployment Date"></column>
					<column
						field="deployment_total_opens"
						header="Newsletter Total Opens"
					></column>
					<column
						field="deployment_unique_opens"
						header="Newsletter Unique Opens"
					></column>
					<column field="total_clicks" header="Total Clicks"></column>
					<column field="unique_clicks" header="Unique Clicks"></column>
					<column header="Total Click Rate" field="total_click_rate"></column>
					<column header="Unique Click Rate" field="unique_click_rate"></column>
				</DataTable>
			</div>
		);
	};
	const DirectLeadsTab = ({ leads }) => {
		return (
			<div>
				<p
					style={{
						border: "2px solid #4ca4ff",
						padding: "10px",
						fontSize: "15px",
						fontWeight: "bold",
						background: "#eaf2ff",
						borderRadius: "4px",
					}}
				>
					{leads.length} Direct Leads
				</p>
				<DataTable
					paginator
					rows={10}
					rowsPerPageOptions={[5, 10, 25, 50]}
					value={leads}
					tableStyle={{ minWidth: "50rem" }}
				>
					<Column field="first_name" header="First Name"></Column>
					<Column field="last_name" header="Last Name"></Column>
					<Column field="email" header="Email"></Column>
					<Column field="phone" header="Phone"></Column>
					<Column field="company_name" header="Company"></Column>
					<Column field="job_function" header="Job Function"></Column>
				</DataTable>
			</div>
		);
	};

	const MarketingInquiriesTab = ({ leads }) => {
		return (
			<div>
				<p
					style={{
						border: "2px solid #4ca4ff",
						padding: "10px",
						fontSize: "15px",
						fontWeight: "bold",
						background: "#eaf2ff",
						borderRadius: "4px",
					}}
				>
					{leads.length} Marketing Inquiries
				</p>
				<DataTable
					paginator
					rows={10}
					rowsPerPageOptions={[5, 10, 25, 50]}
					value={leads}
					tableStyle={{ minWidth: "50rem" }}
				>
					<Column field="first_name" header="First Name"></Column>
					<Column field="last_name" header="Last Name"></Column>
					<Column field="email" header="Email"></Column>
					<Column field="company" header="Company"></Column>
					<Column field="job_function" header="Job Function"></Column>
				</DataTable>
			</div>
		);
	};

	const tabs = [
		{
			label: "Performance",
			key: "Performance",
			content: <PerformanceTab data={data} />,
		},
		{
			label: "Direct Leads",
			key: "DirectLeads",
			content: <DirectLeadsTab leads={directLeads} />,
		},
		{
			label: "Marketing Inquiries",
			key: "MarketingInquiries",
			content: <MarketingInquiriesTab leads={miLeads} />,
		},
	];

	const [activeIndex, setActiveIndex] = useState(3);
	const [activeTab, setActiveTab] = useState(tabs[0]);

	return (
		<div>
			<ReportHeader campaign={campaign} reportName="Sponsored Content Report" />
			<TabMenu
				model={tabs}
				activeIndex={activeIndex}
				onTabChange={(e) => {
					setActiveTab(e.value);
					setActiveIndex(e.index);
				}}
			/>
			<div>{activeTab.content}</div>
		</div>
	);
};

export const NewsletterAdReport = ({ data }) => {
	return NewsletterAdTemplate({ data, reportName: "Newsletter Ad Report" });
};

const NewsletterAdTemplate = ({ data, reportName }) => {
	const campaign = data.campaign;
	const stats = data.analytics;

	const columns = [
		{
			field: "deployment_date",
			header: "Deployment Date",
			body: (rowData) => (
				<StyledReportLink href={rowData.deployment_preview} icon="📅">
					{new Date(rowData.deployment_date).toLocaleDateString()}
				</StyledReportLink>
			),
		},
		{ field: "deployment_total_delivered", header: "Delivered" },
		{ field: "deployment_total_opens", header: "Total Opens" },
		{ field: "deployment_unique_opens", header: "Unique Opens" },
		{
			field: "deployment_total_open_rate",
			header: "Total Open Rate",
			body: (rowData) =>
				(rowData.deployment_total_open_rate * 100).toFixed(2) + "%",
		},
		{
			field: "deployment_unique_open_rate",
			header: "Unique Open Rate",
			body: (rowData) =>
				(rowData.deployment_unique_open_rate * 100).toFixed(2) + "%",
		},
		{ field: "total_clicks", header: "Total Clicks" },
		{ field: "unique_clicks", header: "Unique Clicks" },
		{
			field: "total_click_rate",
			header: "Total Click Rate",
			body: (rowData) => (rowData.total_click_rate * 100).toFixed(2) + "%",
		},
		{
			field: "unique_click_rate",
			header: "Unique Click Rate",
			body: (rowData) => (rowData.unique_click_rate * 100).toFixed(2) + "%",
		},
	];

	const headerGroup = (
		<ColumnGroup>
			<Row>
				<Column
					header="Daily Newsletter Performance"
					colSpan={6}
					style={{ backgroundColor: "#3d85c6", color: "white", borderRadius: 0 }}
				/>
				<Column
					header="Your Campaign Performance"
					colSpan={4}
					style={{ backgroundColor: "#45818e", color: "white", borderRadius: 0 }}
				/>
			</Row>
			<Row>
				<Column
					header="Deployment Date"
					field="deployment_date"
					style={{ backgroundColor: "#3d85c6", color: "white", borderRadius: 0 }}
				/>
				<Column
					header="Delivered"
					field="deployment_total_delivered"
					style={{ backgroundColor: "#3d85c6", color: "white", borderRadius: 0 }}
				/>
				<Column
					header="Total Opens"
					field="deployment_total_opens"
					style={{ backgroundColor: "#3d85c6", color: "white", borderRadius: 0 }}
				/>
				<Column
					header="Unique Opens"
					field="deployment_unique_opens"
					style={{ backgroundColor: "#3d85c6", color: "white", borderRadius: 0 }}
				/>
				<Column
					header="Total Open Rate"
					field="deployment_total_open_rate"
					style={{ backgroundColor: "#3d85c6", color: "white", borderRadius: 0 }}
				/>
				<Column
					header="Unique Open Rate"
					field="deployment_unique_open_rate"
					style={{ backgroundColor: "#3d85c6", color: "white", borderRadius: 0 }}
				/>
				<Column
					header="Total Clicks"
					field="total_clicks"
					style={{ backgroundColor: "#45818e", color: "white", borderRadius: 0 }}
				/>
				<Column
					header="Unique Clicks"
					field="unique_clicks"
					style={{ backgroundColor: "#45818e", color: "white", borderRadius: 0 }}
				/>
				<Column
					header="Total Click Rate"
					field="total_click_rate"
					style={{ backgroundColor: "#45818e", color: "white", borderRadius: 0 }}
				/>
				<Column
					header="Unique Click Rate"
					field="unique_click_rate"
					style={{ backgroundColor: "#45818e", color: "white", borderRadius: 0 }}
				/>
			</Row>
		</ColumnGroup>
	);

	const footerGroup = (
		<ColumnGroup>
			<Row>
				<Column footer="Totals" />
				<Column
					footer={stats.reduce((a, b) => a + b.deployment_total_delivered, 0)}
				/>
				<Column footer={stats.reduce((a, b) => a + b.deployment_total_opens, 0)} />
				<Column footer={stats.reduce((a, b) => a + b.deployment_unique_opens, 0)} />
				<Column
					footer={
						(
							(stats.reduce((a, b) => a + b.deployment_total_open_rate, 0) /
								stats.length) *
							100
						).toFixed(2) + "%"
					}
				/>
				<Column
					footer={
						(
							(stats.reduce((a, b) => a + b.deployment_unique_open_rate, 0) /
								stats.length) *
							100
						).toFixed(2) + "%"
					}
				/>
				<Column footer={stats.reduce((a, b) => a + b.total_clicks, 0)} />
				<Column footer={stats.reduce((a, b) => a + b.unique_clicks, 0)} />
				<Column
					footer={
						(
							(stats.reduce((a, b) => a + b.total_click_rate, 0) / stats.length) *
							100
						).toFixed(2) + "%"
					}
				/>
				<Column
					footer={
						(
							(stats.reduce((a, b) => a + b.unique_click_rate, 0) / stats.length) *
							100
						).toFixed(2) + "%"
					}
				/>
			</Row>
		</ColumnGroup>
	);

	return (
		<div>
			<ReportHeader campaign={campaign} reportName={reportName} />
			<DataTable
				showGridlines
				value={stats}
				headerColumnGroup={headerGroup}
				footerColumnGroup={footerGroup}
				stripedRows
				style={{ border: "3px solid #659ed4", borderRadius: "4px" }}
			>
				{columns.map((col) => (
					<Column
						field={col.field}
						header={col.header}
						key={col.field}
						body={col.body || null}
					/>
				))}
			</DataTable>
		</div>
	);
};

export const LeadGenReport = ({ data }) => {
	const campaign = data.campaign;
	const leads = data.leads;

	return (
		<>
			<ReportHeader campaign={campaign} reportName="Lead Generation Report" />
			<h1
				style={{
					border: "2px solid #4ca4ff",
					padding: "10px",
					fontSize: "15px",
					fontWeight: "bold",
					background: "#eaf2ff",
					borderRadius: "4px",
				}}
			>
				Total Leads: {leads.length}
			</h1>
			<DataTable
				value={leads}
				className="w-full"
				paginator
				rows={10}
				rowsPerPageOptions={[5, 10, 25, 50]}
			>
				<Column field="first_name" header="First Name"></Column>
				<Column field="last_name" header="Last Name"></Column>
				<Column field="email" header="Email"></Column>
				<Column field="company" header="Company"></Column>
				<Column field="job_title" header="Job Title"></Column>
				<Column field="job_function" header="Job Function"></Column>
				<Column field="business_type" header="Business Type"></Column>
			</DataTable>
		</>
	);
};

export const PartnerSolutionsReport = ({ data }) => {
	const PerformanceTab = ({ data }) => {
		const formatPercentage = (rowData, column) => {
			return `${(rowData[column.field] * 100).toFixed(2)}%`;
		};
		return (
			<div>
				<h1
					style={{
						border: "2px solid #4ca4ff",
						padding: "10px",
						fontSize: "15px",
						fontWeight: "bold",
						background: "#eaf2ff",
						borderRadius: "4px",
					}}
				>
					Performance
				</h1>
				<DataTable showGridlines value={[data]}>
					<Column field="deployment_date" header="Deployment Date"></Column>
					<Column field="total_opens" header="Total Opens"></Column>
					<Column field="unique_opens" header="Unique Opens"></Column>
					<Column
						field="total_opens_delivered_rate"
						header="Opens/Delivered Rate"
						body={(rowData) =>
							formatPercentage(rowData, { field: "total_opens_delivered_rate" })
						}
					></Column>
					<Column
						field="unique_opens_delivered_rate"
						header="Unique Opens/Delivered Rate"
						body={(rowData) =>
							formatPercentage(rowData, { field: "unique_opens_delivered_rate" })
						}
					></Column>
					<Column field="total_clicks" header="Total Clicks"></Column>
					<Column field="unique_clicks" header="Unique Clicks"></Column>
					<Column
						field="total_clicks_delivered_rate"
						header="Clicks/Delivered Rate"
						body={(rowData) =>
							formatPercentage(rowData, { field: "total_clicks_delivered_rate" })
						}
					></Column>
					<Column
						field="total_clicks_opens_rate"
						header="Clicks/Opens Rate"
						body={(rowData) =>
							formatPercentage(rowData, { field: "total_clicks_opens_rate" })
						}
					></Column>
					<Column
						field="unique_clicks_opens_rate"
						header="Unique Clicks/Opens Rate"
						body={(rowData) =>
							formatPercentage(rowData, { field: "unique_clicks_opens_rate" })
						}
					></Column>
				</DataTable>
				<div style={{ display: 'flex', gap: '10px', margin: '16px 0' }}>
					<StyledReportLink href={data.deployment_preview} icon="📧">View Deployment Preview</StyledReportLink>
				</div>
			</div>
		);
	};

	const MarketingInquiriesTab = ({ leads }) => {
		return (
			<div>
				<p
					style={{
						border: "2px solid #4ca4ff",
						padding: "10px",
						fontSize: "15px",
						fontWeight: "bold",
						background: "#eaf2ff",
						borderRadius: "4px",
					}}
				>
					{leads.length} Marketing Inquiries
				</p>
				<DataTable
					paginator
					rows={10}
					rowsPerPageOptions={[5, 10, 25, 50]}
					value={leads}
					tableStyle={{ minWidth: "50rem" }}
				>
					<Column field="first_name" header="First Name"></Column>
					<Column field="last_name" header="Last Name"></Column>
					<Column field="email" header="Email"></Column>
					<Column field="company" header="Company"></Column>
					<Column field="job_function" header="Job Function"></Column>
				</DataTable>
			</div>
		);
	};

	const tabs = [
		{
			label: "Performance",
			key: "Performance",
			content: <PerformanceTab data={data.analytics} />,
		},
		{
			label: "Marketing Inquiries",
			key: "MarketingInquiries",
			content: <MarketingInquiriesTab leads={data.leads} />,
		},
	];
	const campaign = data.campaign;
	const [activeIndex, setActiveIndex] = useState(0);
	const [activeTab, setActiveTab] = useState(tabs[0]);

	return (
		<>
			<ReportHeader campaign={campaign} reportName="Partner Solutions Report" />
			<TabMenu
				model={tabs}
				activeIndex={activeIndex}
				onTabChange={(e) => {
					setActiveTab(e.value);
					setActiveIndex(e.index);
				}}
			/>
			<div>{activeTab.content}</div>
		</>
	);
};

export const PodcastVideocastReport = ({ data }) => {
	const PerformanceTab = ({ data }) => {
		console.log("data", data);
		return (
			<DataTable value={[data.campaign]} className="w-full">
				<Column
					header="Publish Date"
					body={(rowData) => rowData?.start_date}
				></Column>
				<Column
					header="Pageviews"
					body={(rowData) => rowData?.pageviews || "N/A"}
				></Column>
				<Column header="Leads" body={() => data.leads.length}></Column>
			</DataTable>
			// <p>{data.leads.length}</p>
		);
	};

	const LeadsTab = ({ leads }) => {
		return (
			<>
				<h1
					style={{
						border: "2px solid #4ca4ff",
						padding: "10px",
						fontSize: "15px",
						fontWeight: "bold",
						background: "#eaf2ff",
						borderRadius: "4px",
					}}
				>
					Total Leads: {leads.length}
				</h1>
				<DataTable
					paginator
					rows={10}
					rowsPerPageOptions={[5, 10, 25, 50]}
					value={leads}
					className="w-full"
				>
					<Column field="first_name" header="First Name"></Column>
					<Column field="last_name" header="Last Name"></Column>
					<Column field="email" header="Email"></Column>
					<Column field="company" header="Company"></Column>
					<Column field="job_function" header="Job Function"></Column>
				</DataTable>
			</>
		);
	};

	const tabs = [
		{
			label: "Performance",
			key: "Performance",
			content: <PerformanceTab data={data} />,
		},
		{
			label: "Leads",
			key: "Leads",
			content: <LeadsTab leads={data.leads} />,
		},
	];

	const [activeIndex, setActiveIndex] = useState(0);
	const [activeTab, setActiveTab] = useState(tabs[0]);
	return (
		<>
			<ReportHeader
				campaign={data.campaign}
				reportName="Podcast/Videocast Report"
			/>
			<TabMenu
				model={tabs}
				activeIndex={activeIndex}
				onTabChange={(e) => {
					setActiveTab(e.value);
					setActiveIndex(e.index);
				}}
			/>
			<div>{activeTab.content}</div>
		</>
	);
};
