import axios from "axios";
import { logoutUser } from "../apis/auth/logoutUser";
import { BASE_API_URI } from '../consts'


axios.interceptors.response.use((response) => {
    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // If unauthorized, redirect to login page
    if (error.response && error.response.status === 401) {
        // remove www-authenticate header to prevent browser from showing login popup on 401
        console.log("Redirecting to login page due to 401 error")
        logoutUser();
        window.location.href = '/login';
    }
    return Promise.reject(error);
  });

// Service function that wraps axios as a facade and provides a base URL for all API requests and can handle common failures
export const fetchFromApi = async (endpoint, params={}, withCredentials=true, base_path=BASE_API_URI) => {
    console.log("fetchFromApi", endpoint, params, withCredentials, base_path)
    try {
        const response = await axios.get(
            base_path + endpoint, 
            { 
                withCredentials: withCredentials,
                params: params
            }
        );
        console.log("API Fetch Succeeded:", response.data[0]);
        return response.data; 
    } catch (error) {
        console.log("API Fetch Failed:", error);
        switch (error.code) {
            case 'ERR_BAD_REQUEST':
                return { error: "Not Found", status: error.response.status}
            case 'ERR_BAD_RESPONSE':
                return { error: "Bad Response", status: error.response.status}
            default:
                return { error: "Something went wrong", status: error.response.status }
        }
    }
}

export const postToApi = async (endpoint, data, withCredentials=true, base_path=BASE_API_URI) => {
    try {
        const response = await axios.post(
            base_path + endpoint, 
            data,
            { 
                withCredentials: withCredentials
            }
        );
        return response.data; // Return data to the caller
    } catch (error) {
        console.log("Something went wrong:", error);
        return null; // Return null or appropriate error handling
    }
}
