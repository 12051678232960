import React, { useState, useEffect, memo } from 'react';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { InputText } from 'primereact/inputtext';
import { Panel } from 'primereact/panel';
import { Calendar } from 'primereact/calendar';
import { getCampaigns } from '../../../apis/advertisers';

import ReportDialog from '../ReportDialog';

export default function ReportFilter() {
    // TODO: Save state to session storage and hydrate from session storage
    const [campaigns, setCampaigns] = useState(null);
    const [reportVisible, setReportVisible] = useState(false);
    const [filters, setFilters] = useState(null);
    const [loading, setLoading] = useState(true);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [selectedCampaign, setSelectedCampaign] = useState(null);
    const [brands] = useState([
        { name: 'Beauty Launchpad', short_name: 'BLP', image: 'amyelsner.png' },
        { name: 'Cosmetics & Toiletries', short_name: 'CT', image: 'annafali.png' },
        { name: 'GCI Magazine', short_name: 'GCI', image: 'asiyajavayant.png' },
        { name: 'Medical Esthetics', short_name: 'ME', image: 'onyamalimba.png' },
        { name: 'Nail Pro', short_name: 'NP', image: 'ionibowcher.png' },
        { name: 'Perfumer & Flavortist', short_name: 'PF', image: 'bernardodominic.png' },
        { name: 'Skin Inc.', short_name: 'SI', image: 'elwinsharvill.png' },
        { name: 'Well Spa', short_name: 'WS', image: 'ivanmagalhaes.png' },
    ]);
    const [salesReps] = useState([
        { name: 'Kim Jednachowski', image: 'kimjednachowski.png' },
        { name: 'Jolly Patel', image: 'jollypatel.png' },
        { name: 'Angelina Lutsenko', image: 'angelinalutsenko.png' },
        { name: 'Barbara Carreon', image: 'barbaracarreon.png' },
        { name: 'Paige Crist', image: 'paigecrist.png' },
        { name: 'Perri Polowy', image: 'perripolowy.png' },
        { name: 'Shamella Anderson', image: 'shamellaanderson.png'},
    ]);

    const [products] = useState(["Sponsored Content", "Newsletter Ad", "Eblast", "Product Round Up", "Partner Solutions", "Webcast", "Website Ad", "Breaking News", "Lead Gen", "Ebook", "Podcast/Videocast" ]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const {data, error} = await getCampaigns();
                // Ensure dates are properly converted
                const processedData = data.map((item) => ({
                    ...item,
                    start_date: new Date(item.start_date)
                }));
                console.log("Sample campaign date:", processedData[0]?.start_date);
                processedData?.results ? 
                    setCampaigns(getCampaignsData(processedData?.results)) : 
                    setCampaigns(getCampaignsData(processedData));
                setLoading(false);
            } catch (error) {
                console.error('Error fetching campaigns:', error);
                setLoading(false);
            }
        };
        // Only initialize filters if they haven't been set yet
        if (!filters) {
            initFilters();
        }
        fetchData();
    }, []);

    const getCampaignsData = (data) => {
        // convert date strings to Date objects
        return data;
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between align-items-center">
                <div>
                    <Button 
                        type="button" 
                        icon="pi pi-filter-slash" 
                        label="Clear" 
                        outlined 
                        onClick={clearFilter}
                        className="mr-2"
                    />
                </div>
                <span className="p-input-icon-left">
                    
                    <InputText
                        value={globalFilterValue}
                        onChange={(e) => onGlobalFilterChange(e)}
                        placeholder="Keyword Search"
                        className="p-inputtext-sm"
                    />
                </span>
            </div>
        )
    };

    const clearFilter = () => {
        const emptyFilters = {
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            advertiser: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            brand: { value: null, matchMode: FilterMatchMode.IN },
            sold_by: { value: null, matchMode: FilterMatchMode.IN },
            product: { value: null, matchMode: FilterMatchMode.EQUALS },
            start_date: { 
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }]
            }
        };
        setFilters(emptyFilters);
        setGlobalFilterValue('');
    };

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            advertiser: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            brand: { value: null, matchMode: FilterMatchMode.IN },
            sold_by: { value: null, matchMode: FilterMatchMode.IN },
            product: { value: null, matchMode: FilterMatchMode.EQUALS },
            start_date: { 
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }]
            }
        });
        setGlobalFilterValue('');
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const brandBodyTemplate = (rowData) => {
        const brand = brands.find(b => b.short_name === rowData.brand);
        return (
            <div className="flex align-items-center gap-2">
                <img alt="flag" src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png" style={{ width: '24px' }} />
                <span>{brand?.name || rowData.brand}</span>
            </div>
        );
    };

    const brandRowFilterTemplate = (options) => {
        return (
            <MultiSelect
                value={options.value}
                options={brands.map(brand => ({ label: brand.name, value: brand.short_name }))}
                onChange={(e) => options.filterApplyCallback(e.value)}
                placeholder="Any"
                className="p-column-filter"
                style={{ minWidth: '14rem' }}
                optionLabel="label"
            />
        );
    };

    const productRowFilterTemplate = (options) => {
        const sortedProducts = products.sort();
        return (
            <Dropdown
            value={options.value}
            options={sortedProducts.map(product => ({ label: product, value: product }))}
            placeholder="Any"
            style={{ width: '100%' }}
            onChange={(e) => {
                options.filterApplyCallback(e.value)
            }}
            showClear
            checkmark={true}
            />
        );
    };

    const soldbyRowFilterTemplate = (options) => {
        return (
            <MultiSelect 
                value={options.value}
                options={salesReps.map(rep => rep.name)}
                placeholder="Any"
                className="p-column-filter browser-default"
                style={{ minWidth: '14rem' }}
                maxSelectedLabels={1}
                onChange={(e) => {
                    options.filterApplyCallback(e.value)
                }}
            />
        );
    };

    const dateBodyTemplate = (rowData) => {
        return formatDate(rowData.start_date);
    };

    const formatDate = (value) => {
        if (!value) return '';
        const date = new Date(value);
        return date.toLocaleDateString('en-US', {
            month: 'short',
            day: '2-digit',
            year: 'numeric'
        });
    };

    const dateFilterTemplate = (options) => {
        return (
            <div className="p-2">
                <Calendar 
                    value={options.value}
                    onChange={(e) => options.filterCallback(e.value, options.index)}
                    dateFormat="mm/dd/yy"
                    placeholder="Select date"
                    showButtonBar
                    className="w-full"
                />
            </div>
        );
    };

    const filterHeaderTemplate = (options) => {
        return (
            <div className="flex flex-column gap-2 p-3">
                <div className="flex align-items-center justify-content-between">
                    <span className="font-bold">Date Filter</span>
                </div>
                <div className="text-sm text-color-secondary">
                    Filter campaigns by date using multiple conditions
                </div>
            </div>
        );
    };

    const filterClearTemplate = (options) => {
        return (
            <Button 
                type="button" 
                icon="pi pi-times"
                label="Clear"
                onClick={options.filterClearCallback} 
                severity="secondary"
                size="small"
                className="p-button-sm"
            />
        );
    };

    const filterApplyTemplate = (options) => {
        return (
            <Button 
                type="button" 
                icon="pi pi-check"
                label="Apply"
                onClick={options.filterApplyCallback} 
                severity="success"
                size="small"
                className="p-button-sm"
            />
        );
    };

    const filterFooterTemplate = () => {
        return (
            <div className="px-3 py-2 bg-gray-50 text-sm text-color-secondary border-top-1 border-gray-200">
                Use multiple filters to refine your search
            </div>
        );
    };

    const onFilter = (e) => {
        setFilters(e.filters);
    };

    const header = renderHeader();
    return (
        <Panel 
            header="Campaign Reports" 
            pt={{
                header: { className: 'bg-blue-600 text-white font-semibold text-xl border-round-top' },
                content: { className: 'p-0' }
            }}
        >
            <DataTable 
                selectionMode="single" 
                onSelectionChange={(e) => {
                    setSelectedCampaign(e.value)
                    setReportVisible(true)
                }} 
                metaKeySelection={false} 
                removableSort 
                selection={selectedCampaign} 
                value={campaigns} 
                paginator 
                showGridlines 
                rows={10} 
                dataKey="id" 
                filters={filters}
                onFilter={onFilter}
                loading={loading}
                globalFilterFields={['advertiser', 'brand', 'sold_by', 'product', 'start_date']} 
                header={header} 
                emptyMessage="No campaigns found."
                stripedRows
                sortField="start_date"
                sortOrder={-1}
            >
                <Column 
                    field="start_date"
                    filterField="start_date" 
                    header="Campaign Date" 
                    filter 
                    filterElement={dateFilterTemplate}
                    dataType="date"
                    body={dateBodyTemplate}
                    sortable 
                    style={{ minWidth: '12rem' }} 
                    showFilterMenu={true}
                    filterMenuStyle={{ width: '300px' }}
                    filterHeader={filterHeaderTemplate}
                    filterFooter={filterFooterTemplate}
                    filterClear={filterClearTemplate}
                    filterApply={filterApplyTemplate}
                    showFilterOperator
                    filterOperator="and"
                    showFilterMatchModes
                    filterMatchModeOptions={[
                        { label: 'Date is', value: FilterMatchMode.DATE_IS },
                        { label: 'Before', value: FilterMatchMode.LESS_THAN },
                        { label: 'After', value: FilterMatchMode.GREATER_THAN }
                    ]}
                />
                <Column 
                    field="advertiser" 
                    header="Advertiser" 
                    filter 
                    filterDisplay="row"
                    filterPlaceholder="Search by advertiser name" 
                    sortable 
                    style={{ minWidth: '12rem' }} 
                />
                <Column 
                    field="product" 
                    header="Product" 
                    filter 
                    filterDisplay="row"
                    filterElement={productRowFilterTemplate} 
                    sortable 
                    style={{ minWidth: '12rem' }}
                />
                <Column 
                    header="Brand" 
                    field="brand" 
                    filterField="brand" 
                    filterDisplay="row"
                    style={{ minWidth: '12rem' }} 
                    filterElement={brandRowFilterTemplate} 
                    body={brandBodyTemplate} 
                    sortable 
                    filter 
                />
                <Column 
                    field="sold_by" 
                    header="Sold By" 
                    filterField='sold_by' 
                    filterDisplay="row"
                    filterElement={soldbyRowFilterTemplate} 
                    filter 
                    sortable 
                    style={{ minWidth: '12rem' }} 
                />
            </DataTable>
            <ReportDialog selectedCampaign={selectedCampaign} reportVisible={reportVisible} setReportVisible={setReportVisible} />
        </Panel>
    );
}