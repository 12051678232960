import { useContext } from 'react'
import { AuthContext } from '../../context/AuthContext'
import { postToApi } from '../../services'

// Sends a JWT from Google to Django and either registers a new
// user or signs in an existing user.
export const useGoogleLogin = (arg) => {
  console.log('useGoogleLogin')
  console.log("Args: ",arg)
    const { login } = useContext(AuthContext);
  
    const loginGoogleUser = async (token) => {
      try {
        const response = await postToApi('/users/auth/google/', { token })
        localStorage.setItem('isAuthenticated', 'true');
        login(response);
      } catch (error) {
        console.log('Something went wrong:', error);
      }
    };
  
    return loginGoogleUser;
  };