import React, { useContext } from 'react';
import { useLocation, Navigate } from 'react-router-dom';

import { AuthContext } from '../../context/AuthContext';

import SocialLoginGoogle from '../../components/core/LoginCard/SocialLoginGoogle';

const GoogleLoginCard = ({ redirectTo }) => {
    return (
        <div className="row">
            <div className="col m12">
                <div className="card">
                    <div className="card-content">
                        <span className="card-title">Login with Google</span>
                        <br></br>
                        <SocialLoginGoogle redirectTo={redirectTo} />
                    </div>
                </div>
            </div>
        </div>
    );
}

const LoginPage = () => {
    const { isAuthenticated } = useContext(AuthContext);
    const location = useLocation();
    const { redirectTo } = location.state || { from: { pathname: "/" } }; // Default to home page if no redirect
    return (
        <>
            {isAuthenticated ? 
            // Logged in, redirect to dashboard
                <Navigate to="/" replace />
            : 
            // Not logged in, show login options
                <GoogleLoginCard redirectTo={redirectTo} />
            }
        </>
    );
}

export default LoginPage;
