import { Dropdown } from "primereact/dropdown"
import { Button } from "primereact/button"
import { useRef, useState, useContext } from "react"
import { getCampaignOverviewReport } from "../../../../apis/advertisers";
import { AuthContext } from "../../../../context/AuthContext";
import { Toast } from "primereact/toast";

export const OverviewReport = ({campaigns}) => {
    const brands = campaigns ? Array.from(new Set(campaigns?.map(({brand}) => brand)), (brand) => ({ value: brand, label: brand })) : [];
    const [selectedBrand, setSelectedBrand] = useState(null);
	const currentYear = new Date().getFullYear().toString();
	const [selectedYear, setSelectedYear] = useState("2024");
    const { user } = useContext(AuthContext);
	const toast = useRef(null);

	// list of years since 2023 to present year
	const yearOptions = Array.from({length: currentYear - 2022}, (_, i) => ({ value: (i + 2023).toString(), label: (i + 2023).toString() }));
	const handleDownload = async () => {
		// add invalid prop to brand dropdown if no brand is selected
		if (!selectedBrand) {
			document.getElementById("brandDropdown").classList.add("p-invalid");
			toast.current.show({severity: "error", summary: "Error", detail: "Brand is required to generate report"});
			return;
		}
		const rs = await getCampaignOverviewReport({advertiser_id: campaigns[0].advertiser_id, year: selectedYear, brand: selectedBrand, userEmail: user?.username});
		if (rs.error) {
			toast.current.show({severity: "error", summary: "Error", detail: "Failed to generate report"});
			return;
		}
		toast.current.show({severity: "success", summary: "Success", detail: "Report will be emailed to " + user?.username + " when ready." });
	}

    return (
    <div className="h-20rem flex align-items-center justify-content-center">
        <Toast ref={toast} />
        <div className="flex flex-column align-items-center gap-4 w-full mx-4">
            {/* <div className="text-xl font-semibold text-blue-800 mb-3">Generate Overview Report</div> */}
            <div className="p-inputgroup w-full max-w-20rem">
                <span className="p-inputgroup-addon">
                    <i className="pi pi-calendar"></i>
                </span>
                <Dropdown 
                    options={yearOptions} 
                    value={selectedYear} 
                    onChange={(e) => setSelectedYear(e.value)}
                    className="w-full"
                />
            </div>
            <div className="p-inputgroup w-full max-w-20rem">
                <span className="p-inputgroup-addon">
                    <i className="pi pi-tag"></i>
                </span>
                <Dropdown 
                    id="brandDropdown" 
                    placeholder="Select Brand" 
                    required 
                    options={brands} 
                    value={selectedBrand} 
                    onChange={(e) => setSelectedBrand(e.value)}
                    className="w-full"
                />
            </div>
            <Button 
                label="Generate Report" 
                icon="pi pi-file-pdf" 
                onClick={() => handleDownload()} 
                className="p-button-raised p-button-rounded"
                size="large"
            />
        </div>
    </div>)
}