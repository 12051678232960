import { Skeleton } from "primereact/skeleton";
import { ProgressSpinner } from "primereact/progressspinner";
import { Panel } from "primereact/panel";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Dialog } from "primereact/dialog";
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Badge } from "primereact/badge";
import { useState, useEffect } from "react";
import { CampaignsByProduct } from "./modules/campaignsByProduct";
import { CampaignDataTable } from "./modules/campaignDataTable";
import { getAdvertiserLeads } from "../../../apis/advertisers";
import { OverviewReport } from "./modules/overviewReport";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import moment from 'moment';
import ReportDialog from "../../Reports/ReportDialog";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Chart } from 'primereact/chart';

const AdvertiserCampaigns = ({ campaigns }) => {
	const [leads, setLeads] = useState(null)
	const [selectedCampaign, setSelectedCampaign] = useState(null)
	const [reportVisible, setReportVisible] = useState(false)
	const [filters, setFilters] = useState(null)
	const [filteredData, setFilteredData] = useState(campaigns)
	const [globalFilterValue, setGlobalFilterValue] = useState('')
	const [jobFunctionData, setJobFunctionData] = useState(null)
	const [businessTypeData, setBusinessTypeData] = useState(null)

	const chartOptions = {
		cutout: '60%',
		plugins: {
			legend: {
				position: 'right',
				labels: {
					usePointStyle: true,
					color: '#495057'
				}
			}
		},
		maintainAspectRatio: false
	}

	const colors = [
		'#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF',
		'#FF9F40', '#33CC99', '#E7E9ED', '#803790', '#00AFF0',
		'#FF8A80', '#B388FF', '#8BC34A', '#FF80AB', '#607D8B',
		'#FFB74D', '#BA68C8', '#7986CB', '#4DB6AC', '#F06292'
	]

	const prepareChartData = (data, field) => {
		if (!data) return null

		// Count occurrences of each value
		const counts = data.reduce((acc, item) => {
			if (!item[field]) return acc
			// Remove the (#) or (##) prefix and get clean label
			const cleanLabel = item[field].replace(/^\([0-9]+\)\s*/, '').trim()
			acc[cleanLabel] = (acc[cleanLabel] || 0) + 1
			return acc
		}, {})

		// Calculate total for percentages
		const total = Object.values(counts).reduce((sum, count) => sum + count, 0)

		// Convert to chart data format and add percentages at the front
		const labels = Object.keys(counts)
		const values = Object.values(counts)
		const backgroundColor = labels.map((_, index) => colors[index % colors.length])
		const labelsWithPercentages = labels.map((label, index) => {
			const percentage = ((values[index] / total) * 100).toFixed(1)
			return `(${percentage}%) ${label}`
		})

		return {
			labels: labelsWithPercentages,
			datasets: [{
				data: values,
				backgroundColor,
				hoverBackgroundColor: backgroundColor
			}]
		}
	}

	const productOptions = [
		'Sponsored Content',
		'Newsletter Ad',
		'Eblast',
		'Product Round Up',
		'Partner Solutions',
		'Webcast',
		'Website Ad',
		'Breaking News',
		'Lead Gen',
		'Ebook',
		'Podcast/Videocast'
	].map(item => ({ label: item, value: item }))

	const brandOptions = [
		{ label: 'GCI', value: 'GCI' },
		{ label: 'SI', value: 'SI' },
		{ label: 'PF', value: 'PF' }
	]

	const soldByOptions = [
		{ label: 'John Smith', value: 'John Smith' },
		{ label: 'Jane Doe', value: 'Jane Doe' }
	]

	const statusOptions = [
		{ label: 'Active', value: 'Active' },
		{ label: 'Completed', value: 'Completed' },
		{ label: 'Pending', value: 'Pending' }
	]

	console.log("Initial campaigns:", campaigns)
	
	// Custom filter function to debug filtering process
	const filterCallback = (value, field) => {
		console.log(`Filtering ${field} with value:`, value);
		console.log('Current filters before update:', filters);
		
		const newFilters = { ...filters };
		newFilters[field] = { value: value, matchMode: FilterMatchMode.EQUALS };
		
		console.log('Updated filters:', newFilters);
		setFilters(newFilters);
	};

	const initFilters = () => {
		const initialFilters = {
			global: { value: null, matchMode: FilterMatchMode.CONTAINS },
			product: { value: null, matchMode: FilterMatchMode.EQUALS },
			brand: { value: null, matchMode: FilterMatchMode.EQUALS },
			sold_by: { value: null, matchMode: FilterMatchMode.EQUALS },
			status: { value: null, matchMode: FilterMatchMode.EQUALS },
			start_date: { 
				operator: FilterOperator.AND,
				constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }]
			}
		};
		console.log("Initializing filters:", initialFilters);
		setFilters(initialFilters);
	};

	const clearFilter = () => {
		console.log("Clearing filters");
		initFilters();
	};

	useEffect(() => {
		console.log("Current filters state:", filters);
		console.log("Current campaigns data:", campaigns);
	}, [filters, campaigns]);


	useEffect(() => {
		console.log("Trying to fetch leads...")
		const fetchData = async () => {
			if (campaigns && campaigns.length > 0) {
				const result = await getAdvertiserLeads(campaigns[0].advertiser_id)
				setLeads(result?.data);
				console.log("Leads results", result)
			}
		}
		fetchData();
		
		// Initialize filters
		if (!filters) {
			initFilters();
		}
	}, [campaigns])

	useEffect(() => {
		if (leads) {
			setJobFunctionData(prepareChartData(leads, 'job_function'))
			setBusinessTypeData(prepareChartData(leads, 'business_type'))
		}
	}, [leads])

	return (
		<div className="">
			{campaigns ? (
				<>
					<div className="grid">
						<div className="col-6">
							<Panel 
								header="Campaigns by Product" 
								pt={{
									header: { className: 'bg-blue-600 text-white font-semibold text-xl border-round-top' },
									content: { className: 'p-0' }
								}}
							>
								<CampaignsByProduct campaigns={campaigns} />
							</Panel>
						</div>
						<div className="col-6">
							<Panel 
								header="Get Overview Report" 
								className="text-center"
								pt={{
									header: { className: 'bg-blue-600 text-white font-semibold text-xl border-round-top' }
								}}
							>
								<OverviewReport campaigns={campaigns} />
							</Panel>
						</div>
						
						<div className="col-12">
							<Panel 
								headerTemplate={(options) => {
									const className = `${options.className} justify-content-between bg-blue-600 text-white font-semibold text-xl border-round-top`;
									return (
										<div className={className}>
											<span>All Campaigns</span>
											<div className="flex align-items-center gap-2">
												<span className="text-base">Total Items:</span>
												<Badge value={campaigns.length} severity="warning" />
											</div>
										</div>
									);
								}}
							>
								<DataTable
									paginator
									rows={10}
									selectionMode="single"
									onRowClick={(e) => {
										setSelectedCampaign(e.data);
										setReportVisible(true);
									}}
									rowsPerPageOptions={[5, 10, 25, 50]}
									value={campaigns}
									className="w-full"
									filterDisplay="row"
									showGridlines
									dataKey="id"
									filters={filters}
									onFilter={(e) => {
										console.log("Filter event triggered:", e);
										console.log("New filters:", e.filters);
										console.log("Filtered data:", e.filteredValue);
										setFilters(e.filters);
										setFilteredData(e.filteredValue);
									}}
									emptyMessage="No records found"
									globalFilterFields={['product', 'brand', 'sold_by', 'status']}
								>
									<Column 
										field="start_date" 
										header="Campaign Date" 
										sortable 
										filter
										dataType="date"
										filterField="start_date"
										showFilterMenu={false}
										filterElement={(options) => {
											const filterOptions = [
												{ label: 'Date is', value: FilterMatchMode.DATE_IS },
												{ label: 'Date is before', value: FilterMatchMode.DATE_BEFORE },
												{ label: 'Date is after', value: FilterMatchMode.DATE_AFTER }
											];

											return (
												<div className="flex gap-2">
													<Dropdown
														value={options.filterConstraint?.matchMode || FilterMatchMode.DATE_IS}
														options={filterOptions}
														onChange={(e) => {
															const constraint = options.filterConstraint || {};
															constraint.matchMode = e.value;
															options.filterCallback(options.value, constraint);
														}}
														placeholder="Select Filter"
														className="p-column-filter"
													/>
													<Calendar
														value={options.value}
														onChange={(e) => {
															const constraint = options.filterConstraint || { matchMode: FilterMatchMode.DATE_IS };
															options.filterCallback(e.value, constraint);
														}}
														dateFormat="M d, yy"
														placeholder="Select Date"
														className="p-column-filter"
													/>
												</div>
											);
										}}
										body={(v) => {
											return moment(v.start_date).format('MMM D, YYYY');
										}}
									/>
									<Column 
										field="product" 
										header="Product" 
										sortable 
										filter
										filterField="product"
										filterPlaceholder="Any"
										showFilterMenu={false}
										filterMatchMode="equals"
										filterElement={options => (
											<Dropdown
												value={options.value || ''}
												options={productOptions}
												onChange={e => options.filterCallback(e.value)}
												placeholder="Any"
												showClear
											/>
										)}
									/>
									<Column 
										field="brand" 
										header="Brand" 
										sortable 
										filter
										filterField="brand"
										showFilterMenu={false}
										filterElement={(options) => (
											<Dropdown
												value={options.value}
												options={brandOptions}
												onChange={(e) => options.filterCallback(e.value)}
												optionLabel="label"
												optionValue="value"
												placeholder="Select Brand"
												className="p-column-filter"
											/>
										)}
									/>
									<Column 
										field="sold_by" 
										header="Sold By" 
										sortable 
										filter
										filterField="sold_by"
										showFilterMenu={false}
										filterElement={(options) => (
											<Dropdown
												value={options.value}
												options={soldByOptions}
												onChange={(e) => options.filterCallback(e.value)}
												optionLabel="label"
												optionValue="value"
												placeholder="Select Seller"
												className="p-column-filter"
											/>
										)}
									/>
									<Column 
										field="status" 
										header="Status" 
										sortable 
										filter
										filterField="status"
										showFilterMenu={false}
										filterElement={(options) => {
											const statuses = ['completed', 'in-progress', 'upcoming'].map(status => ({
												label: status.charAt(0).toUpperCase() + status.slice(1),
												value: status
											}));
											return (
												<Dropdown
													value={options.value}
													options={statuses}
													onChange={(e) => filterCallback(e.value, 'status')}
													placeholder="Select Status"
													className="p-column-filter"
												/>
											);
										}}
										body={(rowData) => {
											const status = rowData.status.toLowerCase();
											let severity = 'info';
											if (status === 'completed') severity = 'success';
											else if (status === 'in-progress') severity = 'warning';
											else if (status === 'upcoming') severity = 'info';
											
											return <Badge value={rowData.status.toUpperCase()} severity={severity} />;
										}}
									/>
								</DataTable>
							</Panel>
						</div>

						<div className="col-8">
							<Panel 
								headerTemplate={(options) => {
									const className = `${options.className} justify-content-between bg-blue-600 text-white font-semibold text-xl border-round-top`;
									return (
										<div className={className}>
											<span>All Direct Leads</span>
											<div className="flex align-items-center gap-2">
												<span className="text-base">Total Leads:</span>
												<Badge value={leads?.length || 0} severity="warning" />
											</div>
										</div>
									);
								}}
							>
							{leads ? 
								<DataTable 
									value={leads}
									showGridlines
									stripedRows
									tableStyle={{ minWidth: '50rem' }}
									paginator
									rows={10}
									rowsPerPageOptions={[10, 25, 50]}
									paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
									currentPageReportTemplate="{first} to {last} of {totalRecords}"
									sortMode="multiple"
									removableSort
								>
									<Column 
										field="date_submitted" 
										header="Date Received" 
										sortable 
										sortField="date_submitted"
										body={(v) => {
											const date = new Date(v.date_submitted);
											return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
										}}
									/>
									<Column field="first_name" header="First Name" sortable />
									<Column field="last_name" header="Last Name" sortable />
									<Column field="email" header="Email" sortable />
									<Column field="job_function" header="Job Function" sortable />
									<Column field="business_type" header="Business Type" sortable />
								</DataTable>
							: <div className="p-2">
								{[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => (
									<div key={i} className="mb-2">
										<Skeleton height="2.5rem" />
									</div>
								))}
							</div>}
							</Panel>
						</div>
						<div className="col-4">
							<div className="grid">
								<div className="col-12">
									<Panel 
										header="Job Function Breakdown"
										pt={{
											header: { className: 'bg-blue-600 text-white font-semibold text-xl border-round-top' }
										}}
									>
										{jobFunctionData ? (
											<div style={{ height: '300px' }}>
												<Chart type="doughnut" data={jobFunctionData} options={chartOptions} />
											</div>
										) : (
											<div className="flex align-items-center justify-content-between" style={{ height: '300px' }}>
										<Skeleton shape="circle" size="200px" />
										<div className="flex flex-column gap-2" style={{ width: '150px' }}>
											{[1, 2, 3, 4, 5].map((i) => (
												<Skeleton key={i} height="1rem" width="1rem" />
											))}
										</div>
									</div>
										)}
									</Panel>
								</div>
								<div className="col-12">
									<Panel 
										header="Business Type Breakdown"
										pt={{
											header: { className: 'bg-blue-600 text-white font-semibold text-xl border-round-top' }
										}}
									>
										{businessTypeData ? (
											<div style={{ height: '300px' }}>
												<Chart type="doughnut" data={businessTypeData} options={chartOptions} />
											</div>
										) : (
											<div className="flex align-items-center justify-content-between" style={{ height: '300px' }}>
										<Skeleton shape="circle" size="200px" />
										<div className="flex flex-column gap-2" style={{ width: '150px' }}>
											{[1, 2, 3, 4, 5].map((i) => (
												<Skeleton key={i} height="1rem" width="1rem" />
											))}
										</div>
									</div>
										)}
									</Panel>
								</div>
							</div>
						</div>
						<ReportDialog 
							selectedCampaign={selectedCampaign} 
							reportVisible={reportVisible} 
							setReportVisible={setReportVisible} 
						/>
					</div>
				</>
			) : (
				<div className="p-4">


					{/* Charts Section */}
					<div className="grid mb-4">
						<div className="col-12 md:col-6">
							<Skeleton height="300px" className="mb-2" />
						</div>
						<div className="col-12 md:col-6">
							<Skeleton height="300px" className="mb-2" />
						</div>
					</div>

					{/* Table Section */}
					<div>
						<Skeleton className="mb-2" height="3rem" />
						{[1, 2, 3, 4, 5].map((i) => (
							<Skeleton key={i} className="mb-2" height="4rem" />
						))}
					</div>
					<div>
						<Skeleton className="mb-2" height="3rem" />
						{[1, 2, 3, 4, 5].map((i) => (
							<Skeleton key={i} className="mb-2" height="4rem" />
						))}
					</div>
				</div>
			)}
		</div>
	);
};

export default AdvertiserCampaigns;
