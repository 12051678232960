import { useState, useEffect } from "react";
import { Dropdown } from "primereact/dropdown";
import { getAdvertisers, getAdvertiserCampaigns } from "../../apis/advertisers";
import AdvertiserCampaigns from "./AdvertiserCampaigns";
import { useLocation } from "react-router-dom";

const ExplorePage = () => {
	// TODO: Save state to session storage and hydrate from session storage
	const location = useLocation();
	const [selectedAdvertiser, setSelectedAdvertiser] = useState(null);
	const [advertiserCampaigns, setadvertiserCampaigns] = useState(null);
	const [advertisers, setAdvertisers] = useState([]);

	const advertiserDropdown = ({selectedAdvertiser, advertisers}) => {
		console.log("advertisers: ",advertisers);
		return <Dropdown
				id="advertiser"
				value={selectedAdvertiser}
				onChange={(e) => {
					setSelectedAdvertiser(e.value);
					setadvertiserCampaigns(null);
				}}
				options={advertisers}
				optionLabel="name"
				placeholder="Select an Advertiser"
				filter
				className="w-7 shadow-2 border-round-xl text-xl"
				panelClassName="border-round-xl text-lg"
				style={{ 
					background: 'var(--blue-600)',
					color: 'white'
				}}
				pt={{
					input: { className: 'text-white' },
					trigger: { className: 'text-white' }
				}}
				inputClassName="text-white placeholder:text-blue-100 hover:bg-blue-700 font-semibold"
			/>
	}

	useEffect(() => {
		const selectedAdvertiser = location.state?.selectedAdvertiser;
		const savedAdvertiser = JSON.parse(sessionStorage.getItem('selectedAdvertiser'));
		
		if (selectedAdvertiser) {
			setSelectedAdvertiser(selectedAdvertiser);
			sessionStorage.setItem('selectedAdvertiser', JSON.stringify(selectedAdvertiser));
		} else if (savedAdvertiser) {
			setSelectedAdvertiser(savedAdvertiser);
		}
	}, [location.state]);

	useEffect(() => {
		if (selectedAdvertiser) {
			sessionStorage.setItem('selectedAdvertiser', JSON.stringify(selectedAdvertiser));
		}
	}, [selectedAdvertiser]);

	useEffect(() => {
		const fetchAdvertisers = async () => {
			const response = await getAdvertisers();
			setAdvertisers(response.data);
		};
		fetchAdvertisers();
	}, []);


	useEffect(() => {
		const fetchadvertiserCampaigns = async () => {
			const response = await getAdvertiserCampaigns(selectedAdvertiser.id);
			setadvertiserCampaigns(response.data);
		};
		if (selectedAdvertiser) {
			try{
				fetchadvertiserCampaigns();
			} catch (error) {
				console.error("Error fetching advertiser campaigns:", error);
			}
		}
	}, [selectedAdvertiser]);

	return (
		<>
			<div className="bg-white shadow-4 p-5">
				<div className="grid">
					<div className="col-12"
					 >
						<div className="text-center p-3">
							{advertiserDropdown({selectedAdvertiser, advertisers})}
						</div>
					</div>
					<div className="text-center col-12">			
						{selectedAdvertiser ? (
							""
						): (<p className="text-xl">To get started, select an advertiser from the dropdown above.</p>)}
					</div>
				</div>
			</div>
			{selectedAdvertiser && 
				<div className="bg-white shadow-4 p-5 mt-3"> 
					<h1 className="text-4xl font-bold text-blue-800 pb-4 border-bottom-2 border-blue-600 mb-4">{selectedAdvertiser.name}</h1>
					<AdvertiserCampaigns campaigns={advertiserCampaigns} />
				</div>
			}
		</>
	);
};

export default ExplorePage;
