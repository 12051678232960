import { GoogleLogin } from '@react-oauth/google';
import { useGoogleLogin } from '../../../apis/auth/useGoogleLogin';


const SocialLoginGoogle = () => {
    const loginGoogleUser = useGoogleLogin();
    const handleLoginSuccess = (response) => {
        const token = response.credential;
        loginGoogleUser(token).then(rs => {
            // Triggers after the user has been logged in
            console.log("User logged in");
        });
    }; 

    return (
        <>
            <GoogleLogin
                onSuccess={handleLoginSuccess}
                onError={err => console.log("Login Failed: ", err)}   
            />
        </>
    );
}

export default SocialLoginGoogle;
