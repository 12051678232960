import React, { createContext, useState, useEffect } from 'react';
import LoginCard from '../components/core/LoginCard';

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(localStorage.getItem('isAuthenticated') === 'true');
    const [spoofedUser, setSpoofedUser] = useState(null);

    // Modified to initialize user state from localStorage
    const [user, setUser] = useState(() => {
        const savedUserData = localStorage.getItem('user');
        return savedUserData ? JSON.parse(savedUserData) : { username: '', firstName: '', lastName: '', id: null};
    });

    // This effect updates localStorage when isAuthenticated changes
    useEffect(() => {
        localStorage.setItem('isAuthenticated', isAuthenticated);
    }, [isAuthenticated]);

    // This effect updates localStorage when user changes
    useEffect(() => {
        localStorage.setItem('user', JSON.stringify(user));
    }, [user]);

    const login = (userData) => {
        setIsAuthenticated(true);
        console.log('Logging in:', userData.username);
        setUser({
            username: userData.username,
            firstName: userData?.first_name, // Assuming these values are provided during login
            lastName: userData?.last_name,
            id: userData?.id
        });
    };

    const logout = () => {
        console.log('Logging out:', user.username);
        setIsAuthenticated(false);
        setUser({ username: '', firstName: '', lastName: '' , id: null});
        localStorage.removeItem('user'); // Clear user from localStorage on logout
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, user, login, logout, spoofedUser, setSpoofedUser }}>
            {/* Header on all pages */}
            {!isAuthenticated && 
                <LoginCard />
            }
            {children}
        </AuthContext.Provider>
    );
};
