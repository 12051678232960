import { useState, useEffect } from 'react';
import { fetchRepData } from '../../apis/user/fetchRepData';

const useRepData = (user, timeframe) => {
    const [repData, setRepData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    
    useEffect(() => {
        const fetchData = async () => {
            if (!user?.email && !user?.username) {
                setError('No user credentials available');
                setLoading(false);
                return;
            }
            
            setLoading(true);
            setError(null);
            
            try {
                const userIdentifier = user.email || user.username;
                const data = await fetchRepData(userIdentifier, timeframe);
                
                if (!data) {
                    throw new Error('No data received from the server');
                }
                
                setRepData(data);
                setError(null);
            } catch (error) {
                console.error('Error fetching rep data:', error);
                setRepData(null);
                setError(error.message || 'Failed to fetch rep data');
            } finally {
                setLoading(false);
            }
        };
        
        fetchData();
    }, [user?.email, user?.username, timeframe]);

    return { repData, loading, error };
}

export default useRepData;